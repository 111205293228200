<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">FUND TRANSFER VOUCHER MONITORING</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md12 class="mx-2">
          <h2>Total: P {{total_amount}}</h2>
          <v-data-table dense
                        :headers="headers"
                        :items="data_items"
                        disable-pagination
                        hide-default-footer
                        height="650"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  {{ item.voucher_no }}
                </td>
                <td>
                  {{ item.cheque_date }}
                </td>
                <td>
                  {{ item.cheque_no }}
                </td>
                <td>
                  {{ item.from_bank_code }}
                </td>
                <td>
                  {{ item.pdc_status===1?formatPrice(item.amount):'' }}
                </td>
                <td>
                  {{ item.pdc_status===1?'Pending':'Withdrew'}}
                </td>
                <td>
                  {{ item.to_bank_code }}
                </td>
                <td>
                  {{ item.is_ftv_deposit_done===1?formatPrice(item.amount):'' }}
                </td>
                <td>
                  {{ item.is_ftv_deposit_done===2?'Deposited':'Pending' }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAccountOutline, mdiAccountCashOutline, mdiInformationOutline} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import category from "@/store/module/data/category";

  const initialState = () => {
    return {
      data_items: [],
      total_amount: '0',
      headers: [
        {text: 'Voucher #', value: 'voucher_no', sortable: true},
        {text: 'Date', value: 'cheque_date', sortable: true},
        {text: 'Cheque #', value: 'cheque_no', sortable: true},
        {text: 'From Bank', value: 'from_bank_code', sortable: true},
        {text: 'Amount For Withdrawal', value: 'amount', sortable: true},
        {text: 'Withdrawal Status', value: 'pdc_status', sortable: true},
        {text: 'To Bank', value: 'to_bank_code', sortable: true},
        {text: 'Amount For Deposit', value: 'amount', sortable: true},
        {text: 'Deposit Status', value: 'is_ftv_deposit_done', sortable: true},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'month_of']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('fund_transfer_vouchers', ['fund_transfer_voucher_monitoring']),
      initialize_data() {
        this.fund_transfer_voucher_monitoring()
          .then(response => {
            this.data_items = response.data
            var tto = 0;
            this.data_items.forEach(function (item) {
              tto += parseFloat(item.amount)
            });
            this.total_amount = this.formatPrice(tto)
          })
          .catch(error => {
            console.log(error)
          })

      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
    },
  }
</script>
